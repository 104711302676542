<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button (click)="toggleSidebar($event)">
            <mat-icon *ngIf="!isOpened">menu</mat-icon>
            <mat-icon *ngIf="isOpened">menu_open</mat-icon>
        </button>
        <span class="logo-title" routerLink="/"></span>
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
            <ul fxLayout="row" fxLayoutGap="20px">
                <li>
                    <button mat-button [matMenuTriggerFor]="helpMenu">
                        <mat-icon>help_outline</mat-icon>
                    </button>
                    <mat-menu #helpMenu="matMenu">
                    <button mat-menu-item routerLink="/page-under-construction">
                        <mat-icon>bug_report</mat-icon>
                        Report a bug
                    </button>
                    </mat-menu>
                </li>
                <li style="display: none;">
                    <button mat-button [matMenuTriggerFor]="personMenu">
                        <mat-icon>person_outline</mat-icon>
                    </button>
                    <mat-menu #personMenu="matMenu">
                    <button mat-menu-item>
                        <mat-icon>exit_to_app</mat-icon>
                        Sign out
                    </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>