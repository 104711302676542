<div class="mat-display-1">
    Hi, I'm Srinivas
</div>



<div fxLayout="row" >
    <div fxFlex.md="50%" fxFlex.lt-md="100%">
        <div class="content">
            <p>
                I’m a software engineer with extensive experience in <i>iCube Consultancy Services Inc</i> with a passion about my work, because I love what I do. As a senior software engineer i'm responsible for the development of software products produced by the company.
                <br /><br />
                I earned a Bachelor’s degree in B.Sc(Chemistry), and then a Master’s degree in Computer Applications from the University of Puducherry. 
                <br /><br />
                For complete profile please <a routerLink="/resume">click here</a>
                <br /><br />
                I appreciate you taking the time to learn about me, and I look forward to hearing from you.
            </p>
        </div>
    </div>
    <div fxFlex.md="50%" fxFlex.lt-md="100%" fxHide.xs="true">
        
    </div>
</div>
