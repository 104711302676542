<div class="error">
    <div class="error-title mat-display-4">
        404
    </div>
    
    <div class="error-content mat-display-1">
        Whoops, page not found. <br/>
        Try after some time, or going <a (click)="back()">back</a> and attempting the action again.
    </div>
    
    <div class="error-footer">
        Please email <a routerLink="/">me</a> if this problem persists.
    </div>
</div>