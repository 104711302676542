<div class="error">
    <div class="error-title mat-display-4">
        <span class="material-icons">
            report_problem
        </span>
    </div>
    
    <div class="error-content mat-display-1">
        Page under construction. <br/>
        Try refreshing the page, or going <a (click)="back()">back</a> and try again after some time.
    </div>
    
    <div class="error-footer">
        Please email <a routerLink="/">me</a> if this problem persists.
    </div>
</div>