<app-header (toggleSidebarForMe)="sideBarToggler($event)" [isOpened]="isOpened"></app-header>

<mat-drawer-container [hasBackdrop]="false">
    <mat-drawer mode="over" [opened]="isOpened">
        <app-sidebar></app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>

<app-footer></app-footer>